import { render, staticRenderFns } from "./general.vue?vue&type=template&id=f6c98f7c&scoped=true&lang=pug"
import script from "./general.vue?vue&type=script&lang=js"
export * from "./general.vue?vue&type=script&lang=js"
import style0 from "./general.vue?vue&type=style&index=0&id=f6c98f7c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6c98f7c",
  null
  
)

export default component.exports